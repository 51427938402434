import styled from 'styled-components'

const animationDelay1 = '.35s'
const animationDelay2 = '.6s'
const animationDelay3 = '.9s'

export const AboutBaseWrapper = styled.section.attrs({
  className: 'ls section_padding_100 columns_padding_25',
})`
  &&& {
    .textWrapper {
      animation-delay: ${animationDelay1};
    }

    img {
      animation-delay: ${animationDelay2};
    }

    .section_header {
      font-size: 37px;
    }
  }
`

export const AboutDetailWrapper = styled.section.attrs({
  className:
    'cs parallax darken_gradient page_about section_padding_top_75 columns_margin_bottom_30 animated fadeInUp',
})`
  &&& {
    animation-delay: ${animationDelay3};
  }
`
