import React from 'react'
import PropTypes from 'prop-types'
import { FaqItemWrapper, FaqItemClickableAnchor } from './styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class FaqItem extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    selectedId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  onSelect = (event) => {
    event.preventDefault()

    const { onSelect, id } = this.props

    onSelect(id)
  }

  render() {
    const { heading, body, selectedId, id } = this.props
    const isSelected = selectedId === id

    return (
      <FaqItemWrapper>
        <div className="panel-group" id="accordion1">
          <div
            className={`panel panel-default ${isSelected ? '' : 'collapsed'}`}
          >
            <div className="panel-heading">
              <h4 className="panel-title">
                <FaqItemClickableAnchor
                  isSelected={isSelected}
                  onClick={this.onSelect}
                >
                  {heading}
                </FaqItemClickableAnchor>
              </h4>
            </div>
            <div
              id="collapse1"
              className={`panel-collapse body ${isSelected ? '' : 'collapsed'}`}
            >
              <div className="panel-body">{body}</div>
            </div>
          </div>
        </div>
      </FaqItemWrapper>
    )
  }
}

export default FaqItem
