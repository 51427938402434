import React, { Component } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import ImageZoom from 'react-medium-image-zoom'
import { FotoGalleryPageWrapper } from './styles'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

export default class FotoGalleryPage extends Component {
  componentDidMount() {
    scroll.scrollToTop({ smooth: false, duration: 0 })

    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <FotoGalleryPageWrapper>
        <Helmet>
          <title>Psychologická poradna Tábor | Fotogalerie</title>
          <meta
            name="description"
            content="Prostory poradny jsou nově rekonstruované, a to tak, aby se zde klient cítil, co možná nejlépe."
          />
        </Helmet>

        <Breadcrumbs text="Fotogalerie" />

        <section className="ls page_portfolio section_padding_top_130 section_padding_bottom_100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="isotope_container isotope row masonry-layout columns_margin_top_0 columns_margin_bottom_30"
                  data-filters=".isotope_filters"
                >
                  <div className="animated fadeInUp foto-1 isotope-item col-lg-6 col-md-6 col-sm-12 category3">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/office-8-750x553.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/office-8-1920x1440.jpg',
                        }}
                      />
                    </div>
                  </div>

                  <div className="animated fadeInUp foto-2 isotope-item col-lg-6 col-md-6 col-sm-12 category2 category4">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/office-6-750x553.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/office-6-1920x1440.jpg',
                        }}
                      />
                    </div>
                  </div>

                  <div className="animated fadeInUp foto-3 isotope-item col-lg-6 col-md-6 col-sm-12 category2 category3">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/office-7-750x553.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/office-7-1920x1440.jpg',
                        }}
                      />
                    </div>
                  </div>

                  <div className="animated fadeInUp foto-4 isotope-item col-lg-6 col-md-6 col-sm-12 category3 category1">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/office-5-750x553.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/office-5-1920x1440.jpg',
                        }}
                      />
                    </div>
                  </div>

                  <div className="animated fadeInUp foto-5 isotope-item col-lg-6 col-md-6 col-sm-12 category2 category4">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/house-1-scaled-small.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/house-1-scaled-big.jpg',
                        }}
                      />
                    </div>
                  </div>

                  <div className="animated fadeInUp foto-6 isotope-item col-lg-6 col-md-6 col-sm-12 category2 category3">
                    <div className="vertical-item gallery-item content-absolute text-center ds">
                      <ImageZoom
                        image={{
                          src: 'images/house-2-scaled-small.jpg',
                          className: 'zoomableImage',
                        }}
                        zoomImage={{
                          src: 'images/house-2-scaled-big.jpg',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FotoGalleryPageWrapper>
    )
  }
}
