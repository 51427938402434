import styled from 'styled-components'
import { primary, primaryDark, error } from '../../colors'

export const ContactFormRoot = styled.div`
  &&&& {
    p {
      @media screen and (max-width: 991px) {
        margin-bottom: 24px;
      }
      @media screen and (max-width: 560px) {
        margin-bottom: 12px;
      }
    }

    a {
      color: ${primaryDark};
    }

    input,
    textarea {
      font-size: 18px;
    }
  }
`

export const ContactFormWrapper = styled.div`
  && {
    & button {
      transition: background-color 0.1s ease !important;
      vertical-align: center;
      background-color: ${primaryDark};

      & span {
        padding: 12px 0;
      }
    }

    & button:hover,
    & button:active,
    & button:focus {
      color: white;
      background-color: ${primary};

      transition: background-color 0.1s ease !important;
      vertical-align: center;
    }
  }
`

export const FormWrapper = styled.div.attrs((props) => ({
  className: `FormWrapper ${props.withAnimation ? 'animated fadeInUp' : ''}`,
}))`
  &&&& {
    padding: 60px 0 24px 0;
  }
`

export const Loader = styled.div`
  display: inline-block;
  margin-right: 20px;
  //margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const SuccessWrapper = styled.div`
  && {
    margin-bottom: 96px;
    min-height: 700px;

    h2 {
      color: ${primaryDark};
    }
  }
`

export const SuccessIcon = styled.i.attrs({
  className: 'highlight fa fa-check',
})`
  && {
    font-size: 112px;
  }
`

export const FailureWrapper = styled.div`
  && {
    margin-bottom: 96px;
    min-height: 550px;

    h2,
    p {
      color: ${error};
    }
  }
`

export const InputErrorMessageWrapper = styled.div.attrs({
  className: 'InputErrorMessageWrapper',
})`
  && {
    color: ${error};
    text-align: left;
    font-weight: bold;
    font-size: 14px;
  }
`

export const SubmitButton = styled.button.attrs((props) => {
  return {
    className: `SubmitButton theme_button color1 with_shadow ${
      props.disabled ? 'isDisabled' : ''
    }`,
    type: 'submit',
  }
})`
  &&& {
  }
`

export const FormHeadingWrapper = styled.div.attrs((props) => {
  return {
    className: `FormHeadingWrapper ${
      props.withAnimation ? 'animated fadeInUp' : ''
    }`,
  }
})`
  &&& {
  }
`
