import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StickyContainer } from 'react-sticky'
import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_ID } from './constants'
import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import WelcomePage from './pages/WelcomePage'
import AboutPage from './pages/AboutPage'
import SessionPage from './pages/SessionPage'
import PricePage from './pages/PricePage'
import FotoGalleryPage from './pages/FotoGalleryPage'
import ContactPage from './pages/ContactPage'
import NotFoundPage from './pages/NotFoundPage'

import {
  ROOT,
  ABOUT_PAGE,
  SESSION_PAGE,
  PRICE_PAGE,
  FOTO_PAGE,
  CONTACT_PAGE,
} from './routes'

import './HTML/css/bootstrap.min.css'
import './HTML/css/animations.css'
import './HTML/css/fonts.css'
import './HTML/css/main.css'

export default class App extends Component {
  constructor(props) {
    super(props)

    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  }

  componentDidMount() {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  render() {
    return (
      <Router>
        <StickyContainer>
          <div id="routeWrapper">
            <Header />

            <Switch>
              <Route exact path={ROOT} component={WelcomePage} />
              <Route path={ABOUT_PAGE} component={AboutPage} />
              <Route path={SESSION_PAGE} component={SessionPage} />
              <Route path={PRICE_PAGE} component={PricePage} />
              <Route path={FOTO_PAGE} component={FotoGalleryPage} />
              <Route path={CONTACT_PAGE} component={ContactPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer />
          </div>
        </StickyContainer>
      </Router>
    )
  }
}
