export const primary = '#91d0cc'
export const primaryDark = '#4bb0a9'
export const primaryDark2 = '#3c8e88'

export const secondary = '#d9be93'
export const secondaryDark = '#caa66e'
export const tertiary = '#444444'

export const error = '#d66961'

export const lightGrey = '#f5f5f5'
