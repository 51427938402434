import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmail from 'isemail'
import ReactGA from 'react-ga'
import axios from 'axios'
import {
  InputErrorMessageWrapper,
  SubmitButton,
  FormWrapper,
  Loader,
  ContactFormRoot,
  FormHeadingWrapper,
  SuccessWrapper,
  SuccessIcon,
  FailureWrapper,
  ContactFormWrapper,
} from './styles'

class ContactForm extends Component {
  static propTypes = {
    primaryButtonText: PropTypes.string,
    withAnimation: PropTypes.bool,
  }

  static defaultProps = {
    primaryButtonText: 'Odeslat',
    withAnimation: false,
  }

  nameRef = React.createRef()

  emailRef = React.createRef()

  phoneRef = React.createRef()

  messageRef = React.createRef()

  defaultState = {
    isSubmitting: false,
    isSubmitted: false,
    isErrored: false,
    nameErrorMessages: '',
    emailErrorMessages: '',
    phoneErrorMessages: '',
    messageErrorMessages: '',
    // used to remember submitted values in case submission fails after http request is sent
    prevName: '',
    prevPhone: '',
    prevMessage: '',
    prevEmail: '',
  }

  state = {
    ...this.defaultState,
  }

  getFormValues = () => {
    return {
      name: this.nameRef.current ? this.nameRef.current.value : '',
      email: this.emailRef.current ? this.emailRef.current.value : '',
      phone: this.phoneRef.current ? this.phoneRef.current.value : '',
      message: this.messageRef.current ? this.messageRef.current.value : '',
    }
  }

  //  Duplicated in server-side code, any changes here must be reflected these as well
  validateName = (name) => {
    const errorMessages = []

    if (name.length > 50) {
      errorMessages.push({
        type: 'name',
        message: 'Jméno nesmí být delší než 50 znaků',
      })
    }

    return errorMessages
  }

  //  Duplicated in server-side code, any changes here must be reflected these as well
  validateEmail = (email) => {
    const errorMessages = []

    if (!email) {
      errorMessages.push({
        type: 'email',
        message: 'Email musí být vyplněn',
      })
    } else if (email.length > 100) {
      errorMessages.push({
        type: 'email',
        message: 'Email nesmí být delší než 100 znaků',
      })
    } else if (!isEmail.validate(email)) {
      errorMessages.push({
        type: 'email',
        message: 'Email nebyl zadán ve správném formátu',
      })
    }

    return errorMessages
  }

  //  Duplicated in server-side code, any changes here must be reflected these as well
  validatePhone = (phone) => {
    const errorMessages = []

    if (!phone) {
      errorMessages.push({
        type: 'phone',
        message: 'Telefon musí být vyplněn',
      })
    } else if (phone.length < 9 || phone.length > 20) {
      errorMessages.push({
        type: 'phone',
        message: 'Telefon musí být dlouhý 9 - 20 znaků',
      })
    }

    return errorMessages
  }

  //  Duplicated in server-side code, any changes here must be reflected these as well
  validateMessage = (message) => {
    const errorMessages = []

    if (!message) {
      errorMessages.push({
        type: 'message',
        message: 'Zpráva musí být vyplněna',
      })
    } else if (message.length < 10 || message.length > 2000) {
      errorMessages.push({
        type: 'message',
        message: 'Zpráva musí být dlouhá 10 - 2000 znaků',
      })
    }

    return errorMessages
  }

  onNameChange = (name) => {
    const errors = this.validateName(name)
    const nameErrorMessages = errors.map((err) => err.message).join(', ')

    this.setState({
      nameErrorMessages,
    })
  }

  onEmailChange = (email) => {
    const errors = this.validateEmail(email)
    const emailErrorMessages = errors.map((err) => err.message).join(', ')

    this.setState({
      emailErrorMessages,
    })
  }

  onPhoneChange = (phone) => {
    const errors = this.validatePhone(phone)
    const phoneErrorMessages = errors.map((err) => err.message).join(', ')

    this.setState({
      phoneErrorMessages,
    })
  }

  onMessageChange = (message) => {
    const errors = this.validateMessage(message)
    const messageErrorMessages = errors.map((err) => err.message).join(', ')

    this.setState({
      messageErrorMessages,
    })
  }

  getIsFormValidStatus = ({ name, email, phone, message } = {}) => {
    const nameErrors = this.validateName(name)
    const emailErrors = this.validateEmail(email)
    const phoneErrors = this.validatePhone(phone)
    const messageErrors = this.validateMessage(message)

    return (
      nameErrors.length === 0 &&
      emailErrors.length === 0 &&
      phoneErrors.length === 0 &&
      messageErrors.length === 0
    )
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { isSubmitting } = this.state

    if (isSubmitting) {
      return
    }

    const { name, email, phone, message } = this.getFormValues()
    const isFormValid = this.getIsFormValidStatus({
      name,
      email,
      phone,
      message,
    })

    if (!isFormValid) {
      if (this.nameRef.current) {
        this.onNameChange(this.nameRef.current.value)
      }

      if (this.emailRef.current) {
        this.onEmailChange(this.emailRef.current.value)
      }

      if (this.phoneRef.current) {
        this.onPhoneChange(this.phoneRef.current.value)
      }

      if (this.messageRef.current) {
        this.onMessageChange(this.messageRef.current.value)
      }

      return
    }

    this.setState(
      {
        isSubmitting: true,
        prevName: name,
        prevPhone: phone,
        prevMessage: message,
        prevEmail: email,
      },
      () => {
        axios
          .post('/api/v1/contact', {
            name,
            phone,
            message,
            email,
          })
          .then((response) => {
            if (response.status === 200) {
              this.onSubmitSuccess(response)
            } else {
              this.onSubmitError(null, response)
            }
          })
          .catch((error) => {
            this.onSubmitError(error, null)
          })
      },
    )
  }

  onSubmitSuccess = () => {
    ReactGA.pageview(`${window.location.pathname}/form-success`)

    this.setState({
      isSubmitting: false,
      isSubmitted: true,
      isErrored: false,
    })
  }

  onSubmitError = () => {
    ReactGA.pageview(`${window.location.pathname}/form-failure`)

    this.setState({
      isSubmitting: false,
      isSubmitted: true,
      isErrored: true,
    })
  }

  onResetState = (event) => {
    event.preventDefault()

    this.setState({ ...this.defaultState })
  }

  renderContactForm = () => {
    const { primaryButtonText, withAnimation } = this.props
    const {
      isSubmitting,
      isSubmitted,
      isErrored,
      nameErrorMessages,
      emailErrorMessages,
      phoneErrorMessages,
      messageErrorMessages,
      prevEmail,
      prevMessage,
      prevName,
      prevPhone,
    } = this.state

    const isFormValid = this.getIsFormValidStatus(this.getFormValues())
    const isFormDisabled = isSubmitting || !isFormValid

    return (
      <ContactFormRoot>
        <FormHeadingWrapper withAnimation={withAnimation}>
          <h2 className="section_header with_icon">Kontaktní formulář</h2>
          <p>
            Objednat se můžete i emailem na adrese{' '}
            <a href="mailto:info@psychologicka-poradna-tabor.cz">
              info@psychologicka-poradna-tabor.cz
            </a>{' '}
            nebo <br /> na tel. <a href="tel:+420606645281">606 645 281</a>
          </p>
        </FormHeadingWrapper>

        <FormWrapper withAnimation={withAnimation}>
          <form
            className="contact-form row columns_margin_bottom_40"
            method="post"
            action="./"
          >
            <div className="col-sm-12">
              <div className="contact-form-name">
                <input
                  type="text"
                  aria-required="true"
                  className={`form-control ${
                    nameErrorMessages ? 'isErrored' : ''
                  }`}
                  placeholder="Jméno a příjmení"
                  ref={this.nameRef}
                  onChange={(event) => this.onNameChange(event.target.value)}
                  defaultValue={prevName}
                />
              </div>
              {nameErrorMessages ? (
                <InputErrorMessageWrapper>
                  {nameErrorMessages}
                </InputErrorMessageWrapper>
              ) : null}
            </div>
            <div className="col-sm-6">
              <div className="contact-form-email">
                <input
                  type="email"
                  className={`form-control ${
                    emailErrorMessages ? 'isErrored' : ''
                  }`}
                  placeholder="Emailová adresa"
                  ref={this.emailRef}
                  onChange={(event) => this.onEmailChange(event.target.value)}
                  defaultValue={prevEmail}
                />
              </div>
              {emailErrorMessages ? (
                <InputErrorMessageWrapper>
                  {emailErrorMessages}
                </InputErrorMessageWrapper>
              ) : null}
            </div>
            <div className="col-sm-6">
              <div className="contact-form-phone">
                <input
                  type="tel"
                  className={`form-control ${
                    phoneErrorMessages ? 'isErrored' : ''
                  }`}
                  placeholder="Telefon"
                  ref={this.phoneRef}
                  onChange={(event) => this.onPhoneChange(event.target.value)}
                  defaultValue={prevPhone}
                />
              </div>
              {phoneErrorMessages ? (
                <InputErrorMessageWrapper>
                  {phoneErrorMessages}
                </InputErrorMessageWrapper>
              ) : null}
            </div>
            <div className="col-sm-12">
              <div className="contact-form-message">
                <textarea
                  aria-required="true"
                  rows="3"
                  className={`form-control ${
                    messageErrorMessages ? 'isErrored' : ''
                  }`}
                  placeholder="Vaše zpráva"
                  ref={this.messageRef}
                  onChange={(event) => this.onMessageChange(event.target.value)}
                  defaultValue={prevMessage}
                />
                {messageErrorMessages ? (
                  <InputErrorMessageWrapper>
                    {messageErrorMessages}
                  </InputErrorMessageWrapper>
                ) : null}
              </div>
            </div>

            {isSubmitted ? (
              isErrored ? (
                this.renderFailure()
              ) : (
                this.renderSuccess()
              )
            ) : (
              <div className="col-sm-12">
                <div className="contact-form-submit topmargin_20">
                  <SubmitButton
                    onClick={this.onSubmit}
                    disabled={isSubmitting}
                    title={
                      isFormDisabled ? 'Formulář nebyl správně vyplněn' : ''
                    }
                  >
                    {isSubmitting ? <Loader /> : null}
                    <span>{isSubmitting ? 'Odesílám' : primaryButtonText}</span>
                  </SubmitButton>
                </div>
              </div>
            )}
          </form>
        </FormWrapper>
      </ContactFormRoot>
    )
  }

  renderSuccess = () => {
    return (
      <SuccessWrapper>
        <h2 className="section_header with_icon">
          Formulář byl úspěšně odeslán
        </h2>
        <p>
          Děkujeme za odeslání formuláře, v následujících dnech Vás budeme
          kontaktovat.
          <br />
          <br />
        </p>
        <SuccessIcon />
      </SuccessWrapper>
    )
  }

  renderFailure = () => {
    return (
      <FailureWrapper>
        <h2 className="section_header with_icon">Formulář nebyl odeslán</h2>
        <p>
          Při odesílání formuláře bohužel nastala chyba. Zkuste formulář odeslat
          znovu. V případě, že bude chyba přetrvávat, kontaktujte mne přímo na
          tel: <a href="tel:+420606645281">606 645 281</a> nebo emailem na{' '}
          <a href="mailto:info@psychologicka-poradna-tabor.cz">
            info@psychologicka-poradna-tabor.cz
          </a>
          .
          <br />
          <br />
        </p>

        <button
          id="contact_form_submit"
          name="contact_submit"
          className="theme_button color1 with_shadow"
          onClick={this.onResetState}
          type="button"
        >
          Zpět na formulář
        </button>
      </FailureWrapper>
    )
  }

  render() {
    return <ContactFormWrapper>{this.renderContactForm()}</ContactFormWrapper>
  }
}

export default ContactForm
