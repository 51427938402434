import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import { mapStyles2, ContactPageMapWrapper } from './styles'

const officeLocation = {
  lat: 49.41361,
  lng: 14.669766,
}

class ContactPageMap extends Component {
  static propTypes = {
    withAnimation: PropTypes.bool,
  }

  static defaultProps = {
    withAnimation: false,
  }

  render() {
    const { withAnimation } = this.props

    return (
      <ContactPageMapWrapper withAnimation={withAnimation}>
        <GoogleMap
          defaultOptions={{ styles: mapStyles2 }}
          defaultZoom={15}
          defaultCenter={officeLocation}
        >
          <Marker position={officeLocation} />
        </GoogleMap>
      </ContactPageMapWrapper>
    )
  }
}

ContactPageMap.propTypes = {}

export default withScriptjs(withGoogleMap(ContactPageMap))
