import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { PriceItemRoot } from './styles'
import { CONTACT_PAGE } from '../../routes'

class PriceItem extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    priceSubText: PropTypes.string.isRequired,
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
    line3: PropTypes.string.isRequired,
    line4: PropTypes.string.isRequired,
    line5: PropTypes.string.isRequired,
    callToAction: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      heading,
      price,
      priceSubText,
      line1,
      line2,
      line3,
      line4,
      line5,
      callToAction,
      isPrimary,
      className,
    } = this.props
    return (
      <PriceItemRoot isPrimary={isPrimary} className={className}>
        <li className="plan-name">
          <h3>{heading}</h3>
        </li>
        <li className={`plan-price grey ${isPrimary ? 'bold' : ''}`}>
          {price}
          <p className="small-text">{priceSubText}</p>
        </li>
        <li className="features-list">
          <ul>
            {line1 ? <li>{line1}</li> : null}
            {line2 ? <li>{line2}</li> : null}
            {line3 ? <li>{line3}</li> : null}
            {line4 ? <li>{line4}</li> : null}
            {line5 ? <li>{line5}</li> : null}
          </ul>
        </li>
        <li className="call-to-action darklinks small-text">
          <NavLink
            to={`${CONTACT_PAGE}/#formular`}
            className="theme_button inverse"
          >
            {callToAction}
          </NavLink>
        </li>
      </PriceItemRoot>
    )
  }
}

PriceItem.propTypes = {}

export default PriceItem
