import styled from 'styled-components'

const animationDelay1 = '.35s'
const animationDelay2 = '.6s'
const animationDelay3 = '1s'
const animationDelay4 = '1.1s'
const animationDelay5 = '1.2s'

export const PricePageWrapper = styled.main.attrs(() => ({
  className: 'page PricePage',
}))`
  &&&&&& {
    .BreadcrumbsWrapper {
      margin-bottom: 92px;

      @media screen and (max-width: 991px) {
        margin-bottom: 24px;
      }
    }

    .PricesWrapper {
      margin-top: 92px;

      .animatedHeader {
        animation-delay: ${animationDelay2};
      }

      .priceItem1 {
        animation-delay: ${animationDelay3};
      }

      .priceItem2 {
        animation-delay: ${animationDelay4};
      }

      .priceItem3 {
        animation-delay: ${animationDelay5};
      }

      @media screen and (max-width: 991px) {
        margin-top: 24px;
      }
    }
  }
`

export const AboutSessionWrapper = styled.div.attrs(() => ({
  className: 'AboutSessionWrapper animated fadeInUp',
}))`
  &&& {
    animation-delay: ${animationDelay1};

    p {
      text-align: center;
    }
  }
`
