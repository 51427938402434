import React, { Component } from 'react'
import * as Scroll from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import {
  WelcomePageWrapper,
  HeroWrapper,
  HeroTextWrapper,
  HeroHeading,
  HeroSubHeading,
  HeroSeparator,
  HeroNameHeading,
  HeroScroller,
} from './styles'
import Specializations from '../../components/Specializations'
import Testimonials from '../../components/Testimonials'
import Faq from '../../components/Faq'
import ContactForm from '../../components/ContactForm'
import Prices from '../../components/Prices'

export default class WelcomePage extends Component {
  componentDidMount() {
    Scroll.animateScroll.scrollToTop({ smooth: false, duration: 0 })

    ReactGA.pageview(window.location.pathname)
  }

  onHeroScroller = () => {
    Scroll.scroller.scrollTo('afterHeroWrapper', {
      smooth: 'easeInOutQuint',
      duration: 1000,
    })
  }

  render() {
    return (
      <WelcomePageWrapper>
        <Helmet>
          <title>Psychologická poradna Tábor | Mgr. Bc. Tereza Bělecká‎</title>
          <meta
            name="description"
            content="Psychologické poradenství i dlouhodobá terapeutická péče - individuální, párová i rodinná. Služby lze využít i anonymně."
          />
        </Helmet>

        <HeroWrapper>
          <HeroTextWrapper>
            <HeroHeading>Psychologická poradna</HeroHeading>

            <HeroSubHeading>Tábor</HeroSubHeading>
            <HeroSeparator />
            <HeroNameHeading>Mgr. Bc. Tereza Bělecká</HeroNameHeading>
          </HeroTextWrapper>

          <HeroScroller onClick={this.onHeroScroller}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </HeroScroller>
        </HeroWrapper>

        <Scroll.Element name="afterHeroWrapper" />

        <Specializations />

        <Testimonials />

        <Faq />

        <Prices />

        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <br />
              <br />
              <br />

              <ContactForm primaryButtonText="Objednat se" />
            </div>
          </div>
        </div>
      </WelcomePageWrapper>
    )
  }
}
