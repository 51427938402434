import React, { Component } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import ImageZoom from 'react-medium-image-zoom'
import { AboutBaseWrapper, AboutDetailWrapper } from './styles'
import Specializations from '../../components/Specializations/Specializations'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

export default class AboutPage extends Component {
  componentDidMount() {
    scroll.scrollToTop({ smooth: false, duration: 0 })

    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <main className="page">
        <Helmet>
          <title>Psychologická poradna Tábor | O mně</title>
          <meta
            name="description"
            content="Můj styl práce je integrativní, tedy se řídí potřebami klienta tak, aby se cítil dobře a bezpečně."
          />
        </Helmet>

        <Breadcrumbs text="O mně" />

        <AboutBaseWrapper>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-push-6">
                <ImageZoom
                  image={{
                    src: 'images/profile-raw-2-546x728.jpg',
                    className: 'zoomableImage animated fadeInUp',
                  }}
                  zoomImage={{
                    src: 'images/profile-raw-2-1200x1600.jpg',
                  }}
                />
              </div>
              <div className="col-md-6 col-md-pull-6 animated fadeInUp textWrapper">
                <h2 className="section_header">Mgr. Bc. Tereza Bělecká</h2>
                <hr className="divider_30_1" />
                <p>
                  Jmenuji se Tereza Bělecká, jsem vystudovaná psycholožka.
                  Absolventka jednak pětiletého jednooborového studia
                  Psychologie na Filozofické fakultě Masarykovy univerzity v
                  Brně a dále pak bakalářského studia v oboru Psychologie se
                  zaměřením na vzdělávání na Západočeské univerzitě v Plzni.{' '}
                </p>
                <p>
                  Po studiu jsem v roce 2015 nastoupila do Centra
                  psychologicko-sociálního poradenství v Rakovníku, jinak řečeno
                  do manželské a rodinné poradny. Zde jsem získala praxi ve
                  vedení sezení s jednotlivci, páry i s celou rodinou, a to v
                  rozsáhlé tématice. Také jsem zde spolulektorovala odborné
                  přípravy pro žadatele o náhradní rodinnou péči (konkrétně
                  pěstouny a pěstouny na přechodnou dobu).
                </p>
                <p>
                  Absolvovala jsem pětiletý psychoterapeutický výcvik v
                  integrativní psychoterapii (780 hodin). Tento výcvik je
                  akreditován Psychiatrickou společností, Asociací klinických
                  psychologů i Společností pro psychoterapii.{' '}
                </p>
                <p>
                  V rámci svého integrativního přístupu používám mj. KBT,
                  Systemický přístup, Terapii zaměřenou na řešení, nedirektivní
                  přístup a další psychoterapeutické a poradenské postupy.
                </p>
                <p>
                  Má práce je pod supervizí psychiatričky MUDr. J. Strašrybkové.
                </p>
                <p>
                  Psychologickou poradnu v Táboře jsem založila v únoru 2019.
                </p>
              </div>
            </div>
          </div>
        </AboutBaseWrapper>
        <AboutDetailWrapper>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-push-2 text-center">
                <h2 className="section_header">
                  Specializuji se na práci s dospělými a dospívajícími
                </h2>
                <br />
                <p className="bold fontsize_18">
                  Můj styl práce je integrativní, tj. můj přístup ke klientovi
                  je určován klientovými potřebami.
                </p>
                <p className="fontsize_18">
                  Má volba tohoto stylu práce vychází z mého přesvědčení, že
                  každý z nás potřebujeme něco jiného, s ohledem na naše
                  osobnostní rysy, věk, životní situaci, aktuální rozpoložení
                  atd. Někdy a někdo zkrátka potřebuje jen vyslechnout, někdo
                  naopak konkrétní rady a vedení, jiný zas rád věci rozebírá do
                  hloubky a druhý naopak dá přednost přímé práci na změně svých
                  postojů, dovedností...
                </p>
                <div className="with_icon topmargin_60">
                  <h5 className="small-text inline-block">
                    Mgr. Bc. Tereza Bělecká
                  </h5>
                </div>
                <img src="/images/Signature.png" alt="" />
              </div>
            </div>
          </div>
        </AboutDetailWrapper>
        <Specializations />
      </main>
    )
  }
}
