import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SpecializationsWrapper } from './styles'
export default class Specializations extends Component {
  static propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
  }

  static defaultProps = {
    heading: 'Poskytované služby',
    subHeading:
      'Poradna nabízí krátkodobé psychologické poradenství i dlouhodobou terapeutickou péči, a to pro osoby starší 15 let.',
  }

  render() {
    const { heading, subHeading } = this.props

    return (
      <SpecializationsWrapper>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header with_icon">{heading}</h2>
              <p>{subHeading}</p>
            </div>
          </div>
          <div className="row columns_padding_0 columns_margin_0 fontsize_16">
            <div className="col-md-3 col-sm-6">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/conflicting.png" alt="" />
                <h4>Vztahové problémy</h4>
                <p>Získání nadhledu vede k porozumění a urovnání konfliktu.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/mind.png" alt="" />

                <h4>Osobnostní růst</h4>
                <p>
                  Chtít víc je základ, ale zjistit jak na to, je prostorem pro
                  práci.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 clear-sm">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/mental.png" alt="" />
                <h4>Kariérní poradenství</h4>
                <p>
                  Čím déle otálíme s krokem ke změně, tím hůře se pak ten krok
                  dělá.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/meditation.png" alt="" />

                <h4>Psychosomatika</h4>
                <p>
                  Někdy tělo protestuje a pacient ani lékaři neví proč. Co když
                  je tělo jen poslem?
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 clear-lg clear-md clear-sm">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/relationship.png" alt="" />

                <h4>Psychiatrické diagnózy</h4>
                <p>
                  Deprese: Výčitky nejsou na místě, chce to jinou strategii a
                  bude líp.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/depression.png" alt="" />
                <h4>Zvládání stresu</h4>
                <p>
                  Jsme silní, ale máme plný nárok říct, už nemůžu, chci změnu.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 clear-sm">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/feelings.png" alt="" />

                <h4>Komunikační dovednosti</h4>
                <p>
                  Umět říct ne, umět rozpoznat manipulaci, umět na ni reagovat,
                  umět.... je jeden ze zdrojů našeho sebevědomí a spokojenosti.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="with_padding text-center teaser">
                <img src="images/service-icons/relaxation.png" alt="" />
                <h4>Nácvik relaxace</h4>
                <p>Je fajn někdy vypnout a nic neřešit ;)</p>
              </div>
            </div>
          </div>
        </div>
      </SpecializationsWrapper>
    )
  }
}
