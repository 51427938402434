import React, { Component } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

import { PricePageWrapper, AboutSessionWrapper } from './styles'
import { PricePageHeader } from '../../components/Prices/styles'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Prices from '../../components/Prices'

export default class PricePage extends Component {
  componentDidMount() {
    scroll.scrollToTop({ smooth: false, duration: 0 })

    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <PricePageWrapper>
        <Helmet>
          <title>Psychologická poradna Tábor | Ceník</title>
          <meta
            name="description"
            content="Nabízím individuální práci (500 Kč /60 min.), párovou i rodinnou terapii (700 Kč /60 min.)"
          />
        </Helmet>
        <Breadcrumbs text="Průběh sezení a ceník" />

        <AboutSessionWrapper>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <PricePageHeader>Jak sezení probíhá?</PricePageHeader>
              </div>
              <div className="col-sm-12">
                <p>Naše první sezení bude poznávací a informativní. </p>
                <p>
                  Prvně Vás seznámím stručně s fungováním mé služby a následně
                  mě Vy seznámíte s tím, s čím přicházíte.{' '}
                </p>
                <p>
                  Na prvním či druhém sezení stanovíme cíl naší práce, s tím se
                  pak i pojí domluva na předběžném počtu našich setkání.{' '}
                </p>
                <p>
                  Veškeré informace, které při sezení zazní, a které mi klient
                  předá, považuji za důvěrné a nikomu dál neposkytuji.{' '}
                </p>
              </div>
            </div>
          </div>
        </AboutSessionWrapper>

        <Prices withAnimation />

        <br />
        <br />
      </PricePageWrapper>
    )
  }
}
