import styled from 'styled-components'

export const SpecializationsWrapper = styled.div.attrs({
  className:
    'SpecializationsWrapper ls section_padding_top_130 section_padding_bottom_100',
})`
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);

  & > .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`
