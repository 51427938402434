import styled from 'styled-components'
import { primaryDark, primary } from '../../colors'

export const MIN_HEADER_ROW_1_HEIGHT = 73
export const MIN_HEADER_ROW_2_HEIGHT = 58

export const HeaderWrapper = styled.div.attrs(() => ({
  className: 'HeaderWrapper page_toplogo table_section table_section_md ls',
}))`
  min-height: ${MIN_HEADER_ROW_1_HEIGHT}px;
  &&&& a.logo {
    padding: 0;
  }

  &&&& .small-teaser {
    margin-top: 0;
    margin-bottom: 0;
  }

  &&&& .teaser_icon i {
    color: #787878;
  }

  &&&& .teaser_icon i.fa-facebook-square {
    font-size: 22px;

    &:hover {
      color: #4267b2;
    }
  }

  &&&& .stickyWrapper {
    z-index: 5;
  }

  &&&& .mainmenu > li > a {
    @media screen and (max-width: 1200px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 15px;
    }
  }

  &&&& .stickyWrapper {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }

  &&&& .toggle_menu {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
      background-color: transparent;
      top: 12px;
      right: 24px;
      & > span,
      & > span:before,
      & > span:after {
        background-color: white;
      }

      & .toggle_menu__text {
        position: relative;
        top: -10px;
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
      }
    }

    @media screen and (max-width: 991px) {
      //right: 0;
      //top: 12px;
      //
      & .toggle_menu__text {
        font-size: 14px;
        top: -2px;
      }
      //
      & > span:before {
        top: -6px;
      }
      & > span:after {
        top: 6px;
      }
    }

    @media screen and (max-width: 560px) {
      right: 0;
      top: 12px;

      & .toggle_menu__text {
        font-size: 12px;
        top: 0;
      }

      & > span:before {
        top: -6px;
      }
      & > span:after {
        top: 6px;
      }
    }
  }
`

export const HeaderLogoRow = styled.div.attrs(() => ({
  className: 'HeaderLogoRow',
}))`
  position: relative;
  z-index: 60;

  @media screen and (max-width: 991px) {
    background-color: ${primary};
    border-bottom: 1px solid ${primaryDark};
  }

  &&&& > .container {
    @media screen and (max-width: 991px) {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    @media screen and (max-width: 560px) {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &&&& .ContactsWrapper {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &&&& .LogoWrapper > div {
    @media screen and (max-width: 991px) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
`

export const LogoImage = styled.img.attrs(() => ({
  className: 'LogoImage',
  src: 'images/service-icons/conflicting.png',
  alt: 'Logo',
}))`
  display: inline-block;
  width: 48px;
  position: relative;
  top: -8px;
`

export const LogoHeadingWrapper = styled.div.attrs(() => ({
  className: 'LogoHeadingWrapper',
}))`
  &&&& {
    display: inline-block;
    padding-left: 16px;
    position: relative;
    top: 4px;

    &:hover .LogoHeading,
    &:hover .LogoSubHeading {
      color: ${primary};

      @media screen and (max-width: 991px) {
        color: white;
      }
    }
  }
`

export const LogoHeading = styled.div.attrs(() => ({
  className: 'LogoHeading',
}))`
  &&&& {
    font-size: 24px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${primaryDark};
    transition: color 0.2s ease-in-out;

    @media screen and (max-width: 991px) {
      color: white;
      font-size: 18px;
    }

    @media screen and (max-width: 560px) {
      font-size: 18px;
    }
  }
`

export const LogoSubHeading = styled.div.attrs(() => ({
  className: 'LogoSubHeading',
}))`
  font-size: 16px;
  line-height: 16px;
  color: ${primaryDark};
  transition: color 0.2s ease-in-out;

  @media screen and (max-width: 991px) {
    color: white;
  }
`

export const ContactHeader = styled.div.attrs(() => ({
  className: 'ContactHeader',
}))`
  font-size: 15px;
  line-height: 14px;
  padding-top: 4px;
  padding-bottom: 10px;
  font-weight: 400;
`

export const ContactText = styled.div.attrs(() => ({
  className: 'ContactText',
}))`
  &&&&,
  &&&& a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: ${primaryDark};

    :hover {
      color: ${primary};
    }
  }
`

export const HeaderMenuRow = styled.nav.attrs((props) => ({
  className: `HeaderMenuRow page_header header_darkgrey bordered_items ${
    props.isOpened ? 'isOpened' : 'isClosed'
  }`,
}))`
  &&&& {
    @media screen and (max-width: 991px) {
      display: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      z-index: 10;
    }

    &.isOpened {
      @media screen and (max-width: 991px) {
        position: fixed;
        top: ${MIN_HEADER_ROW_1_HEIGHT + 1}px;
        left: 0;
        display: block;
        //min-height: auto;
      }
    }

    & .col-sm-12 {
      @media screen and (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`

export const HeaderMenuWrapper = styled.header.attrs(() => ({
  className: 'HeaderMenuWrapper mainmenu_wrapper',
}))`
  &&&& {
    @media screen and (max-width: 991px) {
      position: relative;
      opacity: 1;
      visibility: visible;
    }
  }
`
export const HeaderMenu = styled.ul.attrs(() => ({
  className: 'HeaderMenu mainmenu nav sf-menu',
}))`
  &&&& {
    @media screen and (max-width: 991px) {
      position: relative;
      opacity: 1;
      visibility: visible;
      background-color: transparent;
      padding-top: 10px;
    }
  }
`

export const HeaderMenuItem = styled.li.attrs(() => ({
  className: 'HeaderMenuItem',
}))`
  &&&& {
    position: relative;
    width: 15%;
    &.bigger {
      width: 25%;
    }
    @media screen and (max-width: 991px) {
      width: auto !important;
      background-color: transparent;
    }

    &,
    & a {
      text-align: center;
      @media screen and (max-width: 991px) {
        border-radius: 4px;
      }
    }

    :hover,
    :hover a {
      @media screen and (max-width: 991px) {
        background-color: ${primaryDark} !important;
        color: white !important;
      }
    }
  }
`
