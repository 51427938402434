import React from 'react'
import Slider from 'react-slick'
import {
  TestimonialsWrapper,
  TestimonialsBlockquote,
  TestimonialsRoot,
  TestimonialsHeading,
} from './styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class Testimonials extends React.Component {
  render() {
    return (
      <TestimonialsRoot>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <TestimonialsHeading>Reference klientů</TestimonialsHeading>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <TestimonialsWrapper>
                <Slider
                  dots
                  infinite
                  speed={300}
                  slidesToShow={1}
                  slidesToScroll={1}
                  swipeToSlide
                  swipe
                >
                  <div>
                    <TestimonialsBlockquote>
                      „Paní magistra je velmi příjemná, cítila jsem se u ní moc
                      dobře. Řekne příjemné věci, ale i ty nepříjemné, ale právě
                      ty mě posunuly nejvíc. Ještě jednou děkuji.“
                      <div className="item-meta">
                        <h5>H. Marešová</h5>
                      </div>
                    </TestimonialsBlockquote>
                  </div>
                  <div>
                    <TestimonialsBlockquote>
                      „Sezení bylo přínosné. Rozhovor mi pomohl utřídit si co
                      dál.“
                      <div className="item-meta">
                        <h5>J. Valeš</h5>
                      </div>
                    </TestimonialsBlockquote>
                  </div>
                  <div>
                    <TestimonialsBlockquote>
                      „S manželem jsme takhle v poradně byli poprvé a byli jsme
                      oba příjemně překvapeni. Paní psycholožka dávala prostor
                      nám oběma, hlídala, aby to nesklouzávalo k jiným tématům,
                      jako to umíme a díky tomu jsme se začali posouvat. Těšíme
                      se na další setkání.“
                      <div className="item-meta">
                        <h5>M. Háková</h5>
                      </div>
                    </TestimonialsBlockquote>
                  </div>
                </Slider>
              </TestimonialsWrapper>
            </div>
          </div>
        </div>
      </TestimonialsRoot>
    )
  }
}

export default Testimonials

//
// <TestimonialsWrapper>
// <div className="container">
// <div className="row">
// <Slider
// dots
// infinite
// speed={300}
// slidesToShow={1}
// slidesToScroll={1}
// swipeToSlide
// swipe
// >
// <div>
// <TestimonialsBlockquote>
//                   “for me this was a wonderful experience. i must say that i am
// really impressed.”
//                   <div className="item-meta">
//                     <h5>Myrtle Murray</h5>
//                   </div>
//                   </TestimonialsBlockquote>
// </div>
// <div>
// <TestimonialsBlockquote>
//                   “for me this was a wonderful experience. i must say that i am
// really impressed.”
//                   <div className="item-meta">
//                     <h5>Myrtle Murray</h5>
//                   </div>
//                   </TestimonialsBlockquote>
// </div>
// <div>
// <TestimonialsBlockquote>
//                   “for me this was a wonderful experience. i must say that i am
// really impressed.”
//                   <div className="item-meta">
//                     <h5>Myrtle Murray</h5>
//                   </div>
//                   </TestimonialsBlockquote>
// </div>
// </Slider>
// </div>
// </div>
// </TestimonialsWrapper>
