import styled from 'styled-components'

export const TestimonialsRoot = styled.section.attrs(() => ({
  className:
    'cs parallax darken_gradient page_about section_padding_top_75 columns_margin_bottom_30',
}))`
  &&&& {
    [class*='col-'] {
      margin: 0;
    }
  }
`

export const TestimonialsHeading = styled.h1.attrs(() => ({
  className: 'section_header with_icon',
}))`
  &&&& {
    margin: 0;
  }
`

export const TestimonialsWrapper = styled.div.attrs(() => ({
  className: 'TestimonialsWrapper',
}))`
  &&&& {
    padding-bottom: 72px;
    & * {
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      & li button {
        padding: 0;
        transition: background-color 0.1s ease-out;
        border-radius: 2px;

        :before {
          background-color: rgba(255, 255, 255, 0.8) !important;
          opacity: 1;

          color: transparent;
          content: '';
          top: 5px;
          left: 5px;
          width: 10px;
          height: 10px;
          transition: background-color 0.1s ease-out;
          border-radius: 2px;
        }
      }
    }

    li.slick-active button,
    &&&& li button:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
`

export const TestimonialsBlockquote = styled.blockquote.attrs(() => ({
  className: 'TestimonialsBlockquote',
}))`
  &&&& {
    padding: 72px 0 0 0;
    margin: 0;
    color: white;
    font-size: 24px;
    text-transform: none;
    font-weight: 400;

    @media screen and (max-width: 991px) {
      padding: 56px 0 0 0;
      font-size: 20px;
    }

    & .item-meta h5 {
      color: white;
    }
  }
`
