import styled from 'styled-components'

export const FooterWrapper = styled.footer.attrs(() => ({
  className:
    'FooterWrapper page_footer cs main_color2 table_section section_padding_50 columns_padding_0',
}))`
  &&&& {
    .container {
      @media screen and (min-width: 769px and max-width: 1200px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }

    & a.link {
      color: white;
      :hover {
        color: #ececec;
        text-decoration: underline;
      }
    }

    & .logo {
      padding-top: 0;
      padding-bottom: 0;

      @media screen and (max-width: 1200px) {
        font-size: 36px;
      }

      .subLogo {
        display: block;
        font-size: 22px;
        margin-top: 16px;

        @media screen and (max-width: 1200px) {
          margin-top: 0;
        }
      }
    }

    & .menu li:before {
      @media screen and (max-width: 1200px) {
        margin: 0 16px 0 16px;
      }
    }
  }
`
