import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import {
  PricePageHeader,
  PricePageHeaderDescription,
  PricesWrapper,
} from './styles'
import PriceItem from './PriceItem'

class Prices extends Component {
  static propTypes = {
    className: PropTypes.string,
    withAnimation: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    withAnimation: false,
  }

  renderPriceItem1 = () => {
    const { withAnimation } = this.props

    return (
      <div className="col-md-4 col-sm-12">
        <PriceItem
          className={`priceItem1 ${withAnimation ? 'animated fadeInUp' : ''}`}
          heading="Párová terapie"
          price="1700 Kč"
          priceSubText="za 60 minut"
          line1={<span>nebo 2300,- Kč za 90 min</span>}
          line2="Platba v hotovosti na sezení"
          line3="Není hrazeno zdravotní pojišťovnou"
          callToAction="Objednat"
        />
      </div>
    )
  }

  renderPriceItem2 = () => {
    const { withAnimation } = this.props
    return (
      <div className="col-md-4 col-sm-12">
        <PriceItem
          className={`priceItem2 ${withAnimation ? 'animated fadeInUp' : ''}`}
          heading="Individuální terapie"
          price="1200 Kč"
          priceSubText="za 60 minut"
          line1={<span>nebo 1700,- Kč za 90 min</span>}
          line2="Platba v hotovosti na sezení"
          line3="Není hrazeno zdravotní pojišťovnou"
          callToAction="Objednat"
          isPrimary
        />
      </div>
    )
  }

  renderPriceItem3 = () => {
    const { withAnimation } = this.props

    return (
      <div className="col-md-4 col-sm-12">
        <PriceItem
          className={`priceItem3 ${withAnimation ? 'animated fadeInUp' : ''}`}
          heading="Rodinná terapie"
          price="1700 Kč"
          priceSubText="za 60 minut"
          line1={<span>nebo 2300,- Kč za 90 min</span>}
          line2="Platba v hotovosti na sezení"
          line3="Není hrazeno zdravotní pojišťovnou"
          callToAction="Objednat"
        />
      </div>
    )
  }

  render() {
    const { className, withAnimation } = this.props
    return (
      <PricesWrapper className={className}>
        <div className="container">
          <div className="row">
            <div
              className={`col-sm-12 text-center animatedHeader ${
                withAnimation ? 'animated fadeInUp' : ''
              }`}
            >
              <PricePageHeader>Ceny poskytovaných služeb</PricePageHeader>
              <PricePageHeaderDescription>
                Poradna je nestátní ambulantní pracoviště. Jednotlivé konzultace
                nejsou hrazeny zdravotní pojišťovnou.
              </PricePageHeaderDescription>
            </div>
          </div>
          <div className="row">
            <MediaQuery query="(min-width: 991px)">
              {this.renderPriceItem1()}
              {this.renderPriceItem2()}
              {this.renderPriceItem3()}
            </MediaQuery>
            <MediaQuery query="(max-width: 991px)">
              {this.renderPriceItem2()}
              {this.renderPriceItem1()}
              {this.renderPriceItem3()}
            </MediaQuery>
          </div>
        </div>
      </PricesWrapper>
    )
  }
}

export default Prices
