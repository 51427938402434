import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import './NotFoundPage.css'
import { ROOT } from '../../routes'

export default class NotFoundPage extends Component {
  componentDidMount() {
    scroll.scrollToTop({ smooth: false, duration: 0 })

    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <main className="page NotFoundPage">
        <Helmet>
          <title>Psychologická poradna Tábor | Stránka nenalezena</title>
          <meta
            name="description"
            content="Stránka kterou se snažíte zobrazit nebyla naletzena."
          />
        </Helmet>

        <section className="ls section_404 background_cover no_overlay section_padding_top_150 section_padding_bottom_150">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center text-sm-left">
                <div className="inline-block text-center">
                  <p className="not_found">
                    <span className="highlight">404</span>
                  </p>
                  <h2>Stránka nebyla nalezena</h2>
                  <p>
                    <Link to={ROOT} className="theme_button color1 with_shadow">
                      Zpět na úvodní stránku
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}
