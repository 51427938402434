import styled from 'styled-components'

export const BreadcrumbsWrapper = styled.div.attrs(() => ({
  className: 'BreadcrumbsWrapper',
}))`
  &&&& {
    background-image: url(/images/hero-background.png);
    background-position: 35% 25%;
    background-size: cover;
    @media screen and (max-width: 560px) {
      background-position: 45% 35%;
    }

    h2 {
      margin-bottom: 0;
      color: white;
      font-size: 36px;
      text-shadow: 0.015em 0 0.2em rgba(82, 82, 82, 0.7);

      @media screen and (max-width: 560px) {
        font-size: 28px;
      }
    }
    .container {
      padding-top: 40px;
      padding-bottom: 40px;

      @media screen and (max-width: 560px) {
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }
  }
`
