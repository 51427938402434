import styled from 'styled-components'

const animationDelay1 = '.3s'
const animationDelay2 = '.45s'
const animationDelay3 = '.6s'
const animationDelay4 = '.75s'
const animationDelay5 = '.9s'
const animationDelay6 = '1.05s'

export const FotoGalleryPageWrapper = styled.main.attrs({
  className: 'FotoGalleryPageWrapper page',
})`
  &&& {
    .zoomableImage:hover {
      filter: brightness(0.9);
    }

    .item-content.darken_gradient {
      pointer-events: none;
    }

    .foto-1 {
      animation-delay: ${animationDelay1};
    }

    .foto-2 {
      animation-delay: ${animationDelay2};
    }

    .foto-3 {
      animation-delay: ${animationDelay3};
    }

    .foto-4 {
      animation-delay: ${animationDelay4};
    }

    .foto-5 {
      animation-delay: ${animationDelay5};
    }

    .foto-6 {
      animation-delay: ${animationDelay6};
    }
  }
`
