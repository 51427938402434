import styled from 'styled-components'
import {
  MIN_HEADER_ROW_1_HEIGHT,
  MIN_HEADER_ROW_2_HEIGHT,
} from '../../components/Header/styles'

const transitionDelay1 = '.3s'
const transitionDelay2 = '.4s'
const transitionDelay3 = '.5s'
const transitionDelay4 = '.9s'
const transitionDelay5 = '1.4s'

export const WelcomePageWrapper = styled.main.attrs({
  className: 'page WelcomePage ',
})`
  &&&& {
    & .PricesWrapper {
      margin-top: 0 !important;
    }
  }
`

export const HeroWrapper = styled.div`
  position: relative;
  height: calc(100vh - ${MIN_HEADER_ROW_1_HEIGHT + MIN_HEADER_ROW_2_HEIGHT}px);
  height: calc(
    var(--vh, 1vh) * 100 -
      ${MIN_HEADER_ROW_1_HEIGHT + MIN_HEADER_ROW_2_HEIGHT}px
  );

  background-image: url('/images/hero-background.png');
  background-size: cover;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 991px) {
    height: calc(100vh - ${MIN_HEADER_ROW_1_HEIGHT}px);
    height: calc(var(--vh, 1vh) * 100 - ${MIN_HEADER_ROW_1_HEIGHT}px);
  }
`

export const HeroTextWrapper = styled.div`
  &&&& {
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
    padding-left: 24px;
    padding-right: 24px;
  }
`

export const HeroHeading = styled.h1.attrs({
  className: 'animated fadeInUp HeroHeading',
})`
  display: inline-block;
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 110px;
  font-weight: 700;
  color: white;
  text-shadow: 0.015em 0 0.2em rgba(45, 104, 101, 0.5);
  animation-delay: ${transitionDelay1} !important;

  @media screen and (max-width: 1920px) {
    font-size: 86px;
  }

  @media screen and (max-width: 991px) {
    font-size: 64px;
  }

  @media screen and (max-width: 560px) {
    font-size: 40px;
  }
`
export const HeroSubHeading = styled.h3.attrs({
  className: 'HeroSubHeading animated fadeInUp',
})`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: white;
  margin: 0;
  text-shadow: 0.015em 0 0.2em rgba(45, 104, 101, 0.5);
  animation-delay: ${transitionDelay2};

  @media screen and (max-width: 1920px) {
    font-size: 32px;
  }

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }

  @media screen and (max-width: 560px) {
    font-size: 20px;
  }
`

export const HeroSeparator = styled.div.attrs({
  className: 'HeroSubHeading animated fadeInUp',
})`
  width: 400px;
  height: 1px;
  margin: 40px auto 32px auto;
  background-color: white;
  animation-delay: ${transitionDelay3};

  @media screen and (max-width: 1920px) {
    width: 250px;
    margin: 28px auto 28px auto;
  }

  @media screen and (max-width: 560px) {
    width: 200px;
    margin: 20px auto 20px auto;
  }
`

export const HeroNameHeading = styled.h3.attrs({
  className: 'HeroNameHeading animated fadeInUp',
})`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  color: white;
  margin: 0;
  text-shadow: 0.015em 0 0.2em rgba(45, 104, 101, 0.5);
  animation-delay: ${transitionDelay4};

  @media screen and (max-width: 1920px) {
    font-size: 52px;
  }

  @media screen and (max-width: 991px) {
    font-size: 44px;
  }

  @media screen and (max-width: 560px) {
    font-size: 30px;
  }
`

export const HeroScroller = styled.button.attrs({
  className: 'HeroScroller animated fadeInUp',
})`
  position: absolute;
  left: 48%;
  bottom: 24px;
  transform: translateX(-48%);
  background-color: transparent;
  animation-delay: ${transitionDelay5};
  padding: 0;
  margin: 0;

  & svg {
    width: 80px;
    height: 80px;
    fill: white;

    @media screen and (max-width: 991px) {
      width: 64px;
      height: 64px;
    }

    @media screen and (max-width: 560px) {
      width: 44px;
      height: 44px;
      left: 46%;
      transform: translateX(-46%);
    }
  }
`
