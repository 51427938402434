import styled from 'styled-components'
import { lightGrey } from '../../colors'

const animationDelay1 = '.3s'
const animationDelay2 = '.45s'
const animationDelay3 = '.55s'
const animationDelay4 = '.65s'
const animationDelay5 = '.75s'
const animationDelay6 = '.85s'
const animationDelay7 = '1.1s'
const animationDelay8 = '1.4s'

export const ContactPageRoot = styled.main.attrs({
  className: 'page ContactPage',
})`
  &&& {
    h3 {
      color: #6d6d6d;
      font-size: 36px;
      text-align: center;
      margin-bottom: 40px;
      flex: 0 0 100%;
    }

    .contactsIcon {
      min-width: 32px;
    }

    .FormHeadingWrapper {
      animation-delay: ${animationDelay7};
    }

    .FormWrapper {
      animation-delay: ${animationDelay8};
    }
  }
`

export const ContactsType = styled.strong`
  display: inline-block;
  min-width: 72px;
  padding-right: 12px;
  user-select: none;
`

export const ContactsText = styled.span`
  & a {
    color: #333;
  }
`

export const ContactsWrapperInner = styled.div`
  position: relative;
`

export const ContactsItem = styled.div.attrs({
  className: 'small-teaser media fontsize_16 animated fadeInUp',
})`
  &.ContactsItem1 {
    animation-delay: ${animationDelay1};
  }

  &.ContactsItem2 {
    animation-delay: ${animationDelay2};
  }

  &.ContactsItem3 {
    animation-delay: ${animationDelay3};
  }

  &.ContactsItem4 {
    animation-delay: ${animationDelay4};
  }

  &.ContactsItem5 {
    animation-delay: ${animationDelay5};
  }

  &.ContactsItem6 {
    animation-delay: ${animationDelay6};
  }
`

export const ContactsWrapper = styled.div.attrs({
  className: 'ContactsWrapper',
})`
  &&&& {
    padding: 40px 0;
    background-color: ${lightGrey};

    [class*='col-'] {
      margin-top: 0;
    }
  }
`

export const ContactPageMapWrapper = styled.div.attrs((props) => {
  return {
    className: `ContactPageMapWrapper ${
      props.withAnimation ? 'animated fadeInUp' : ''
    }`,
  }
})`
  &&& {
    animation-delay: ${animationDelay7};
  }
`

export const MapLoadingElement = styled.div`
  height: 100%;
`

export const MapContainerElement = styled.div`
  height: 600px;
`

export const MapElement = styled.div`
  height: 100%;
`

export const mapStyles2 = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6195a0',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        lightness: '0',
      },
      {
        saturation: '0',
      },
      {
        color: '#f5f5f2',
      },
      {
        gamma: '1',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'all',
    stylers: [
      {
        lightness: '-3',
      },
      {
        gamma: '1.00',
      },
    ],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#bae5ce',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fac9a9',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#787878',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'labels.icon',
    stylers: [
      {
        hue: '#0a00ff',
      },
      {
        saturation: '-77',
      },
      {
        gamma: '0.57',
      },
      {
        lightness: '0',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#43321e',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.icon',
    stylers: [
      {
        hue: '#ff6c00',
      },
      {
        lightness: '4',
      },
      {
        gamma: '0.75',
      },
      {
        saturation: '-68',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#eaf6f8',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c7eced',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-49',
      },
      {
        saturation: '-53',
      },
      {
        gamma: '0.79',
      },
    ],
  },
]
