import React from 'react'
import { FaqWrapper } from './styles'
import FaqItem from './FaqItem'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class Faq extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedId: '',
    }
  }

  onSelect = (id) => {
    this.setState((prevState) => {
      if (prevState.selectedId === id) {
        return { selectedId: '' }
      }

      return { selectedId: id }
    })
  }

  render() {
    const { selectedId } = this.state

    return (
      <FaqWrapper>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header with_icon">Často kladené otázky</h2>
              <p>
                Pokud zde nenaleznete odpověďi na své otázky, neváhejte mne
                kontaktovat, a to buď emailem na adrese{' '}
                <a href="mailto:info@psychologicka-poradna-tabor.cz">
                  info@psychologicka-poradna-tabor.cz
                </a>{' '}
                nebo na tel: <a href="tel:+420606645281">606 645 281</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FaqItem
                id="1"
                selectedId={selectedId}
                heading="Jak bude probíhat první setkání? Mám mít něco s sebou?"
                body="Nemusíte s sebou mít žádné dokumenty, žádné doklady. Na prvním setkání Vás v prvních pěti minutách informuji o chodu poradny a pak zcela běžným rozhovorem probereme vše, co budete Vy sami chtít. Na konci prvního sezení, či nanejvýše druhého, si stanovíme jasný cíl naší práce, díky kterému budeme moci i odhadnout kolik setkání budeme k jeho dosažení potřebovat."
                onSelect={this.onSelect}
              />
              <FaqItem
                id="2"
                selectedId={selectedId}
                heading="Lze absolvovat sezení zdarma, na pojišťovnu?"
                body="Bohužel nelze, poradna není dotovaná státem a nemá smlouvu s pojišťovnou."
                onSelect={this.onSelect}
              />
              <FaqItem
                id="3"
                selectedId={selectedId}
                heading="Nechci, aby někdo věděl, že docházím k psychologovi, bude mé jméno někde zaznamenáno?"
                body="Nebude. Jelikož nemáme žádné závazky ani k pojišťovně, ani k žádnému zřizovateli – tj. nepředkládáte žádné doklady. Jaké osobní údaje sdělíte je jen čistě na Vás."
                onSelect={this.onSelect}
              />
            </div>
            <div className="col-md-6">
              <FaqItem
                id="4"
                selectedId={selectedId}
                heading="Kolik sezení obvykle musí proběhnout, aby mi to pomohlo?"
                body="Na to bohužel nelze přesně odpovědět, vždy záleží na druhu problematiky, se kterou klient přichází, na celkovém kontextu – tj. motivace klienta k práci, životní situace, podpora a případná spolupráce rodiny atd. Je však jisté, že hned na prvních sezeních si společně s klientem stanovíme, na čem konkrétně budeme pracovat a kolik sezení tomu jsme oba ochotni věnovat."
                onSelect={this.onSelect}
              />
              <FaqItem
                id="5"
                selectedId={selectedId}
                heading="Jak často je potřeba docházet na terapii?"
                body="Klienti obvykle docházejí 1x měsíčně. Na vyžádání klienta lze práci zintenzivnit, a to až na frekvenci 1x za 14 dnů."
                onSelect={this.onSelect}
              />
              <FaqItem
                id="6"
                selectedId={selectedId}
                heading="Lze platit kreditní kartou?"
                body="Bohužel nelze, platbu lze provádět jen hotově."
                onSelect={this.onSelect}
              />
              <FaqItem
                id="7"
                selectedId={selectedId}
                heading="Mohu docházet i se svým dítětem?"
                body="Naše služby jsou určeny pro osoby starší 15 let. Lze tedy zvolit rodinné sezení, kdy rodič/rodiče dochází se svým dospívajícím dítětem či může Vaše dítě docházet samo na individuální sezení s tím, že je potřeba na prvním sezení podepsat informovaný souhlas jedním z rodičů."
                onSelect={this.onSelect}
              />
            </div>
          </div>
        </div>
      </FaqWrapper>
    )
  }
}

export default Faq
