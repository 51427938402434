import styled from 'styled-components'
import { primary, primaryDark, primaryDark2 } from '../../colors'
const easeOutQuint = 'cubic-bezier(0.23, 1, 0.32, 1)'

export const PricesWrapper = styled.div.attrs(() => ({
  className: 'PricesWrapper',
}))`
  &&& {
    margin-top: 48px;
  }
`

export const PriceItemRoot = styled.div.attrs((props) => ({
  className: `PriceItemRoot price-table grey style1 ${
    props.isPrimary ? 'isPrimary' : ''
  }`,
}))`
  &&& {
    width: 100%;
    max-width: 430px;
    margin-right: 30px;
    min-height: 460px;
    border-color: ${primary};
    transition: 0.2s ${easeOutQuint} background-color,
      0.2s ${easeOutQuint} border-color, 0.2s ${easeOutQuint} min-height;

    @media screen and (max-width: 991px) {
      margin: 0 auto 24px auto;
      max-width: 530px;
    }

    :hover {
      border-color: ${primaryDark};

      .plan-name {
        background-color: ${(props) =>
          props.isPrimary ? primaryDark2 : primaryDark} !important;
      }
    }

    &.isPrimary .plan-name {
      background-color: ${primaryDark};
    }

    & .plan-name {
      padding: 32px 0;
      background-color: ${primary};

      font-weight: 600;
      transition: 0.2s ${easeOutQuint} background-color,
        0.2s ${easeOutQuint} border-color, 0.2s ${easeOutQuint} min-height;
    }

    & .plan-price {
      p {
        font-weight: bold;
        font-size: 13px;
      }
    }

    .theme_button {
      transition: 0.2s ${easeOutQuint} background-color,
        0.2s ${easeOutQuint} color;

      :hover {
        background-color: ${primaryDark};
        color: white;
      }
    }
  }
`

export const PricePageHeader = styled.h2.attrs(() => ({
  className: 'PricePageHeader section_header with_icon',
}))`
  &&&& {
    @media screen and (max-width: 991px) {
      margin-top: 72px;
    }
  }
`

export const PricePageHeaderDescription = styled.p.attrs(() => ({
  className: 'PricePageHeaderDescription section_header_description',
}))``
