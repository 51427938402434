import styled from 'styled-components'
import { primaryDark } from '../../colors'

export const FaqWrapper = styled.section.attrs({
  id: 'faq',
  className: 'ls ',
})`
  &&&& {
    p {
      @media screen and (max-width: 991px) {
        margin-bottom: 24px;
      }
      @media screen and (max-width: 560px) {
        margin-bottom: 12px;
      }

      a {
        color: ${primaryDark};
      }
    }

    padding: 100px 0 0 0;
    margin: 0;
    min-height: 850px;
  }
`

export const FaqItemWrapper = styled.div.attrs({
  className: (props) => `${props.isSelected ? 'isSelected' : ''}`,
})`
  &&&& {
    .heading {
      :hover {
        cursor: pointer;
      }

      .isSelected:after {
        visibility: hidden;
      }
    }

    .body {
      max-height: 250px;
      visibility: visible;
      opacity: 1;
      transition: max-height 0.2s ease, opacity 0.2s ease-in-out;

      @media screen and (max-width: 520px) {
        max-height: 500px;
      }

      &.collapsed {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
`

export const FaqItemClickableAnchor = styled.a.attrs({
  href: '#',
  className: (props) => `heading ${props.isSelected ? '' : 'collapsed'}`,
})`
  &&&& {
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
`
