import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { animateScroll, scroller, Element } from 'react-scroll'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'
import ContactPageMap from './ContactPageMap'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import {
  MapLoadingElement,
  MapContainerElement,
  MapElement,
  ContactPageRoot,
  ContactsWrapper,
  ContactsWrapperInner,
  ContactsItem,
  ContactsType,
  ContactsText,
} from './styles'
import ContactForm from '../../components/ContactForm'

class ContactPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { location } = this.props

    if (location.hash === '#formular') {
      scroller.scrollTo('formular', {
        duration: 0,
        smooth: false,
      })
    } else {
      animateScroll.scrollToTop({ smooth: false, duration: 0 })
    }

    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <ContactPageRoot>
        <Helmet>
          <title>Psychologická poradna Tábor | Kontakty</title>
          <meta
            name="description"
            content="Lze mne kontaktovat jak telefonicky, přes email či přes nově zřízené Facebook stránky."
          />
        </Helmet>
        <Breadcrumbs text="Kontaktní informace" />

        <ContactsWrapper>
          <div className="container">
            <div className="row">
              <ContactsWrapperInner>
                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem1">
                    <div className="media-left contactsIcon">
                      <i className="highlight rt-icon2-clock2" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Psycholožka:</ContactsType>
                      <ContactsText>Mgr. Bc. Tereza Bělecká</ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem2">
                    <div className="media-left contactsIcon">
                      <i className="highlight rt-icon2-clock2" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Otevírací doba:</ContactsType>
                      <ContactsText>
                        Dle domluvy (po-pá 09:00 - 21:00)
                      </ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem2">
                    <div className="media-left contactsIcon">
                      <i className="highlight fa fa-map-marker" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Adresa:</ContactsType>
                      <ContactsText>Smetanova 663, Tábor</ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem3">
                    <div className="media-left contactsIcon">
                      <i className="highlight rt-icon2-clock2" />
                    </div>
                    <div className="media-body">
                      <ContactsType>IČO:</ContactsType>
                      <ContactsText>04698487</ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem4">
                    <div className="media-left contactsIcon">
                      <i className="highlight fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Telefon:</ContactsType>
                      <ContactsText>
                        <a href="tel:+420606645281">606 645 281</a>
                      </ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem5">
                    <div className="media-left contactsIcon">
                      <i className="highlight rt-icon2-clock2" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Živnostenský rejstřík:</ContactsType>
                      <ContactsText>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.rzp.cz/cgi-bin/aps_cacheWEB.sh?VSS_SERV=ZVWSBJFND&Action=Search&PRESVYBER=0&PODLE=subjekt&ICO=04698487&OBCHJM=&ROLES=P&OKRES=&OBEC=&CASTOBCE=&ULICE=&COR=&COZ=&CDOM=&JMENO=&PRIJMENI=&NAROZENI=&ROLE=&VYPIS=1"
                        >
                          Odkaz na zápis v ŽR
                        </a>
                      </ContactsText>
                    </div>
                  </ContactsItem>
                </div>

                <div className="col-sm-6 col-xs-12">
                  <ContactsItem className="ContactsItem6">
                    <div className="media-left contactsIcon">
                      <i className="highlight fa fa-envelope-o" />
                    </div>
                    <div className="media-body">
                      <ContactsType>Email:</ContactsType>
                      <ContactsText>
                        <a href="mailto:info@psychologicka-poradna-tabor.cz">
                          info@psychologicka-poradna-tabor.cz
                        </a>
                      </ContactsText>
                    </div>
                  </ContactsItem>
                </div>
              </ContactsWrapperInner>
            </div>
          </div>
        </ContactsWrapper>

        <div className="container">
          <div className="row">
            <Element id="formular" name="formular" />
            <br />
            <br />
            <br />
            <br />
            <div className="col-sm-12 text-center">
              <ContactForm withAnimation />
            </div>
          </div>
        </div>

        <ContactPageMap
          withAnimation
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZOMAriPT6JYWhIN2I4cBDJBena42cvhM&v=3.exp"
          isMarkerShown
          loadingElement={<MapLoadingElement />}
          containerElement={<MapContainerElement />}
          mapElement={<MapElement />}
        />
      </ContactPageRoot>
    )
  }
}

export default withRouter(ContactPage)
