import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BreadcrumbsWrapper } from './styles'

class Breadcrumbs extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const { text, className } = this.props
    return (
      <BreadcrumbsWrapper className={className}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="animated fadeInUp">{text}</h2>
            </div>
          </div>
        </div>
      </BreadcrumbsWrapper>
    )
  }
}

export default Breadcrumbs
