import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Sticky } from 'react-sticky'
import MediaQuery from 'react-responsive'
import {
  ROOT,
  ABOUT_PAGE,
  PRICE_PAGE,
  FOTO_PAGE,
  CONTACT_PAGE,
} from '../../routes'
import {
  HeaderWrapper,
  LogoImage,
  LogoHeading,
  LogoHeadingWrapper,
  LogoSubHeading,
  ContactHeader,
  ContactText,
  HeaderLogoRow,
  HeaderMenuRow,
  HeaderMenuWrapper,
  HeaderMenu,
  HeaderMenuItem,
} from './styles'
import './Header.css'

export default class Header extends Component {
  state = {
    isOpened: false,
  }

  onIsOpenedToggle = () => {
    this.setState((prevState) => ({ isOpened: !prevState.isOpened }))
  }

  resetIsOpened = () => this.setState({ isOpened: false })

  renderHeaderLogoRow = () => {
    const { isOpened } = this.state

    return (
      <HeaderLogoRow>
        <div className="container">
          <div className="LogoWrapper row">
            <div className="col-md-4 col-xs-12">
              <NavLink exact to={ROOT} className="logo">
                <LogoImage />
                <LogoHeadingWrapper>
                  <LogoHeading>Psychologická poradna</LogoHeading>
                  <LogoSubHeading>Tábor</LogoSubHeading>
                </LogoHeadingWrapper>
              </NavLink>
              <span
                className={`toggle_menu ${isOpened ? 'mobile-active' : ''}`}
                onClick={this.onIsOpenedToggle}
              >
                <div className="toggle_menu__text">Menu</div>
                <span />
              </span>
            </div>
            <div className="ContactsWrapper col-md-8 col-xs-6 text-center text-md-right">
              <div className="media small-teaser">
                <div className="media-left">
                  <div className="teaser_icon highlight fontsize_18">
                    <i className="fa fa-phone" />
                  </div>
                </div>
                <div className="media-body">
                  <ContactHeader>Telefon:</ContactHeader>
                  <ContactText>
                    <a href="tel:+420606645281">606 645 281</a>
                  </ContactText>
                </div>
              </div>

              <div className="media small-teaser">
                <div className="media-left">
                  <div className="teaser_icon highlight fontsize_18">
                    <i className="fa fa-envelope" />
                  </div>
                </div>
                <div className="media-body">
                  <ContactHeader>Email:</ContactHeader>
                  <ContactText>
                    <a href="mailto:info@psychologicka-poradna-tabor.cz">
                      info@psychologicka-poradna-tabor.cz
                    </a>
                  </ContactText>
                </div>
              </div>

              <div className="media small-teaser">
                <div className="media-left">
                  <div className="teaser_icon highlight fontsize_22">
                    <a
                      href="https://www.facebook.com/psychologicka.poradna.tabor/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook-square" />
                    </a>
                  </div>
                </div>
                <div className="media-body">
                  <ContactHeader>&nbsp;</ContactHeader>
                  <ContactText>&nbsp;</ContactText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeaderLogoRow>
    )
  }

  renderMenuRow = () => {
    const { isOpened } = this.state

    return (
      <HeaderMenuRow isOpened={isOpened}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <HeaderMenuWrapper>
                <HeaderMenu>
                  <HeaderMenuItem>
                    <NavLink exact to={ROOT} onClick={this.resetIsOpened}>
                      Úvod
                    </NavLink>
                  </HeaderMenuItem>
                  <HeaderMenuItem>
                    <NavLink exact to={ABOUT_PAGE} onClick={this.resetIsOpened}>
                      O mně
                    </NavLink>
                  </HeaderMenuItem>
                  <HeaderMenuItem className="bigger">
                    <NavLink exact to={PRICE_PAGE} onClick={this.resetIsOpened}>
                      Průběh sezení a ceník
                    </NavLink>
                  </HeaderMenuItem>
                  <HeaderMenuItem>
                    <NavLink exact to={FOTO_PAGE} onClick={this.resetIsOpened}>
                      Fotogalerie
                    </NavLink>
                  </HeaderMenuItem>
                  <HeaderMenuItem className="bigger">
                    <NavLink
                      exact
                      to={CONTACT_PAGE}
                      onClick={this.resetIsOpened}
                    >
                      Kontakt a objednání
                    </NavLink>
                  </HeaderMenuItem>
                </HeaderMenu>
              </HeaderMenuWrapper>
            </div>
          </div>
        </div>
      </HeaderMenuRow>
    )
  }

  render() {
    const { isOpened } = this.state
    // console.log('Header.render()')
    return (
      <HeaderWrapper>
        <MediaQuery query="(min-width: 991px)">
          {this.renderHeaderLogoRow()}
          <Sticky className="stickyWrapper">{this.renderMenuRow()}</Sticky>
        </MediaQuery>
        <MediaQuery query="(max-width: 991px)">
          <Sticky
            className={`stickyWrapper ${isOpened ? 'isOPened' : 'isClosed'}`}
          >
            {this.renderHeaderLogoRow()}
            {this.renderMenuRow()}
          </Sticky>
        </MediaQuery>
      </HeaderWrapper>
    )
  }
}
