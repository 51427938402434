import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import { FooterWrapper } from './styles'
import {
  ROOT,
  ABOUT_PAGE,
  PRICE_PAGE,
  FOTO_PAGE,
  CONTACT_PAGE,
} from '../../routes'

export default class Footer extends PureComponent {
  render() {
    return (
      <FooterWrapper>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-sm-push-4 text-center">
              <NavLink exact to={ROOT} className="logo big text-shadow">
                Psychologická poradna
                <span className="subLogo">Tábor</span>
              </NavLink>
            </div>

            <div className="col-sm-4 col-sm-pull-4 text-center text-sm-left text-md-left">
              <div className="widget widget_nav_menu greylinks">
                <ul className="menu divided_content wide_divider">
                  <li className="">
                    <NavLink className="link" exact to={ROOT}>
                      Úvod
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink className="link" exact to={ABOUT_PAGE}>
                      O mně
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink className="link" exact to={PRICE_PAGE}>
                      Sezení a ceník
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 text-center text-sm-right text-md-left">
              <div className="widget widget_nav_menu greylinks">
                <ul className="menu divided_content wide_divider">
                  <li className="">
                    <NavLink className="link" exact to={FOTO_PAGE}>
                      Fotogalerie
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink className="link" exact to={CONTACT_PAGE}>
                      Kontakt
                    </NavLink>
                  </li>
                  <li className="">
                    <a
                      href="https://www.facebook.com/psychologicka.poradna.tabor/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </FooterWrapper>
    )
  }
}
